import React from 'react'
import { Link, useNavigate } from 'react-router-dom'

import { CUSTOM_SETTING_WIKI_URL } from '@/components/CustomSettings/constants.ts'
import { UserRole, UserRoleValue } from '@/constants'
import DashboardVerticalNavigation from '@/layouts/DashboardLayout/DashboardVerticalNavigation.tsx'
import { UsersRo } from '@/services/data-contracts'
import { useGetCustomSettingByName } from '@/services/hooks/custom-settings/useGetCustomSettingByName.tsx'
import { useCurrentUser } from '@/services/hooks/useCurrentUser'
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet'
import CurrencyExchangeOutlinedIcon from '@mui/icons-material/CurrencyExchangeOutlined'
import Diversity3Icon from '@mui/icons-material/Diversity3'
import LiveHelpIcon from '@mui/icons-material/LiveHelp'
import LocalMallIcon from '@mui/icons-material/LocalMall'
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom'
import PersonIcon from '@mui/icons-material/Person'
import StyleOutlinedIcon from '@mui/icons-material/StyleOutlined'
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle'
import { Box, Button, useMediaQuery } from '@mui/material'

type NavigationItem = {
  key: string
  route: ((user: UsersRo) => string) | string
  icon?: React.ReactElement
  label: string
}

const TOURNAMENTS_ROUTE: NavigationItem = {
  key: 'tournaments',
  icon: <StyleOutlinedIcon />,
  label: 'Турниры',
  route: '/cabinet/tournaments',
}

const FUNDS_TRANSFERS_ROUTE: NavigationItem = {
  key: 'funds-transfers',
  icon: <CurrencyExchangeOutlinedIcon />,
  label: 'Переводы',
  route: '/cabinet/funds-transfers',
}

const USERS_ROUTE: NavigationItem = {
  key: 'users',
  icon: <PersonIcon />,
  label: 'Пользователи',
  route: '/cabinet/users',
}

const USERS_GROUPS_ROUTE: NavigationItem = {
  key: 'user-groups',
  icon: <Diversity3Icon />,
  label: 'Группы',
  route: '/cabinet/user-groups',
}

const BALANCES_ROUTE: NavigationItem = {
  key: 'rooms-balances',
  icon: <AccountBalanceWalletIcon />,
  label: 'Кабинет',
  route: '/cabinet/rooms-balances',
}

// const USERS_REPORTS_ROUTE: NavigationItem = {
//   key: 'user-reports',
//   icon: <AssignmentIcon />,
//   label: 'Отчёты',
//   route: user => `/cabinet/user-reports/${user.id}`,
// }

const ROOMS_ROUTE: NavigationItem = {
  key: 'rooms',
  icon: <MeetingRoomIcon />,
  label: 'Румы',
  route: '/cabinet/rooms',
}

const ADMIN_USERS_TOTAL_ROUGE: NavigationItem = {
  key: 'users-total',
  icon: <SupervisedUserCircleIcon />,
  label: 'Средства',
  route: '/cabinet/users-total',
}

const USER_CONTRACTS_ROUTE: NavigationItem = {
  key: 'user-contracts',
  icon: <LocalMallIcon />,
  label: 'Пакеты',
  route: '/cabinet/user-contracts',
}

export const PLAYER_ROUTES: NavigationItem[] = [
  TOURNAMENTS_ROUTE,
  FUNDS_TRANSFERS_ROUTE,
  BALANCES_ROUTE,
]

export const COACH_ROUTES = [TOURNAMENTS_ROUTE, USERS_GROUPS_ROUTE]

export const SELECTOR_ROUTES = [TOURNAMENTS_ROUTE]

export const ADMIN_ROUTES = [
  TOURNAMENTS_ROUTE,
  FUNDS_TRANSFERS_ROUTE,
  USERS_ROUTE,
  USERS_GROUPS_ROUTE,
  ROOMS_ROUTE,
  ADMIN_USERS_TOTAL_ROUGE,
  USER_CONTRACTS_ROUTE,
  BALANCES_ROUTE,
]

const ROUTES_MAP = {
  [UserRole.player]: PLAYER_ROUTES,
  [UserRole.coach]: COACH_ROUTES,
  [UserRole.selector]: SELECTOR_ROUTES,
  [UserRole.admin]: ADMIN_ROUTES,
} as Record<UserRoleValue, NavigationItem[]>

export const DashboardNavigation = () => {
  const navigate = useNavigate()
  // @ts-ignore
  const xl = useMediaQuery(theme => theme.breakpoints.up('xl'))
  const { currentUser } = useCurrentUser()
  const { data: wikiInfo } = useGetCustomSettingByName({
    name: CUSTOM_SETTING_WIKI_URL,
  })

  const routes = currentUser?.role ? ROUTES_MAP[currentUser.role] : []

  if (!currentUser) {
    return null
  }

  return (
    <Box sx={{ display: 'flex', gap: '8px' }} component="nav">
      {xl && (
        <>
          {routes.map(({ key, label, icon, route }: NavigationItem) => {
            const href =
              typeof route === 'function' ? route(currentUser) : route

            return (
              <Button
                component={Link}
                to={href}
                onClick={() => {
                  navigate(href)
                }}
                key={key}
                variant="text"
                startIcon={icon}
                sx={{ color: 'text.primary', cursor: 'pointer' }}
              >
                {label}
              </Button>
            )
          })}
          {wikiInfo && (
            <Button
              onClick={() => {
                window.open(
                  // @ts-ignore
                  wikiInfo?.value?.content,
                  '_blank', // <- This is what makes it open in a new window.
                )
              }}
              key={CUSTOM_SETTING_WIKI_URL}
              variant="text"
              startIcon={<LiveHelpIcon />}
              sx={{ color: 'text.primary', cursor: 'pointer' }}
            >
              {/* @ts-ignore */}
              {wikiInfo?.value?.title as string}
            </Button>
          )}
        </>
      )}

      {!xl && (
        <>
          {routes.slice(0, 3).map(({ key, label, icon, route }) => {
            const href =
              typeof route === 'function' ? route(currentUser) : route

            return (
              <Button
                component={Link}
                to={href}
                onClick={() => {
                  navigate(href)
                }}
                key={key}
                variant="text"
                startIcon={icon}
                sx={{ color: 'text.primary', cursor: 'pointer' }}
              >
                {label}
              </Button>
            )
          })}

          <DashboardVerticalNavigation
            options={routes
              .slice(3, routes.length - 1)
              .map(({ key, icon, label, route }) => {
                const href =
                  typeof route === 'function' ? route(currentUser) : route

                return {
                  value: key,
                  label: (
                    <Button
                      component={Link}
                      to={href}
                      onClick={() => {
                        navigate(href)
                      }}
                      key={key}
                      variant="text"
                      startIcon={icon}
                      sx={{ color: 'text.primary', cursor: 'pointer' }}
                    >
                      {label}
                    </Button>
                  ),
                }
              })}
          />
        </>
      )}
    </Box>
  )
}
