import { useMutation, useQueryClient } from '@tanstack/react-query'

import { Auth } from '@/services/api/auth'
import { LoginRo, LoginUserDto } from '@/services/data-contracts'
import { TokensStorage } from '@/services/tokens-storage'
import { AxiosErrorResponse } from '@/types'

type UseAuthProps = {
  onLoginSuccess?: () => void;
  onLoginError?: () => void;
  onLogoutSuccess?: () => void;
  onLogoutError?: () => void;
}

export const useAuth = ({ onLoginSuccess, onLoginError, onLogoutSuccess, onLogoutError }: UseAuthProps = {}) => {
  const queryClient = useQueryClient()

  const loginMutation = useMutation<LoginRo, AxiosErrorResponse, LoginUserDto>(
    ['auth', 'login'],
    async (payload: LoginUserDto) => {
      const response = await Auth.login(payload)
      return response.data
    },
    {
      onSuccess: (data) => {
        TokensStorage.set({ accessToken: data.accessToken, refreshToken: data.refreshToken })
        onLoginSuccess?.()
      },
      onError: onLoginError,
    })

  const logoutMutation = useMutation(
    ['auth', 'logout'],
    async () => {
      const response = await Auth.logout()
      return response.data;
    },
    {
      onSuccess: onLogoutSuccess,
      onError: onLogoutError,
      onSettled: async () => {
        TokensStorage.clear()
        await queryClient.clear()
        await queryClient.invalidateQueries()
      }
    })

  return {
    loginMutation,
    logoutMutation,
  }
}
